<template lang="pug">
q-dialog.history(v-model="show", transition-show="slide-left", transition-hide="slide-right", @hide="closeModal")
  .history-wrapper
    table.history-table(border="1")
      thead
        tr
          th(v-for="column in columns", :key="column.name", :style="column.column_style") {{ column.label }}
      tbody
        tr(v-for="item in tableData", :key="item.id")
          th(v-for="column in columns", :key="column.name + item.id") 
            div(v-if="column.name === 'changes'") 
              table.history-changes
                thead
                  tr
                    th.history-changes__name {{ locales.name }}
                    th.history-changes__was {{ locales.was }}
                    th.history-changes__became {{ locales.became }}
                tbody
                  tr(v-for="(value, name) in item['changes']")
                    th {{ name }}
                    th {{ getTableValue(value[0]) }}
                    th {{ getTableValue(value[1]) }}
            div(v-else) {{ getTableValue(item[column.name]) }}
</template>

<script setup>
import { computed, ref, onBeforeMount, onMounted, provide } from "vue";
import backend from "@/api";
import { handleError } from "@/services/handleErrors";
import i18n from "@/plugins/vue-i18n";
import { currentLocale } from "@/services/useLocales";
import { format, parseISO } from "date-fns";

const props = defineProps({
  show: { type: Boolean, default: false },
  path: { type: String, default: "" },
});

const emit = defineEmits(["close"]);

const show = ref(props.show);
const columns = ref([]);
const tableData = ref([]);
const page = ref(1);
const hasNextPage = ref(false);
const path = computed(() => props.path);

const locales = i18n["messages"][currentLocale.value]["history"];

const closeModal = () => {
  emit("close");
};

const getTableValue = val => {
  if (!val) {
    return "-";
  }
  const isoDate = parseISO(val);
  if (isoDate.toString() !== "Invalid Date") {
    const userDateTime = new Date(isoDate);
    return `${userDateTime.toLocaleDateString("ru")} ${userDateTime.toLocaleTimeString("ru", { timeStyle: "short" })}`;
  }
  if (Array.isArray(val)) {
    return val.join(", ");
  }
  return val;
};

onBeforeMount(async () => {
  try {
    const params = {
      infinite_scroll: {
        per_page: 20,
        page: 1,
      },
    };
    const { data } = await backend.index(path.value, { params });
    if (columns.value.length === 0) {
      columns.value = data.columns;
    }
    tableData.value = data.data;
  } catch (e) {
    await handleError(e);
  }
});
</script>

<style scoped lang="scss">
.history-wrapper {
  width: 1400px;
  height: 800px;
  background-color: var(--field-background);
  padding: 30px;
  border-radius: 30px;
  border-color: var(--field-border);
  color: var(--dashboard-label-color);

  &::-webkit-scrollbar-track {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.history-table {
  width: 100%;
  border-spacing: 0;
  border-left: 0;
  border-top: 0;

  thead {
    background-color: #5b7aff;
    color: #fff;
    height: 40px;
    border-color: #fff;
    border: 0;
    border-color: var(--dashboard-card-table-tr-background);
  }

  tbody {
    border: 0;
    border-color: var(--dashboard-card-table-tr-background);
  }
}

.history-changes {
  width: 100%;

  &__name {
    width: 40%;
  }

  &__was {
    width: 30%;
  }

  &__became {
    width: 30%;
  }

  thead {
    height: 30px;
  }

  th {
    word-break: break-all;
  }
}
</style>
