<template lang="pug">
.expansion-item(v-if="!loading", :class="`level-${level} ${isOpen ? 'opened' : ''}`")
  .expansion-item__head
    .expansion-item__head__title(
      v-for="column in columns",
      :key="column.name + values.id",
      :style="{ width: column.width, paddingLeft: `${getColumnPaddingLeft(column)}px` }"
    )
      .primary(v-if="column.name === 'title'")
        inline-svg.expansion-item__head__building-icon(
          :src="require(`../../../../assets/icons/object_pass/${levelName}.svg`)",
          :style="{ height: `${getIconHeightByName(levelName)}px`, margin: levelName === 'objects' ? '21px 12px' : '12px' }"
        )
        .header
          .header__main {{ values.title }}
          .header__secondary {{ values.subtitle }}
          .header__secondary {{ `№ ${values.id}` }}
      .expansion-item__head__title(v-else) {{ getTitleValue(values[column.name]) }}
    .expansion-item__head__expand(style="width: 2%", v-if="values.next_level")
      Transition(name="rotate", mode="out-in")
        inline-svg.expand-icon(:src="openIcon", @click="changeOpen", :key="isOpen.toString()")
    .expansion-item__head__expand-plug(v-else, style="width: 2%")
  .expansion-item__head-sep(
    v-if="level > 0 && !isOpen && !lastItem",
    :style="{ marginLeft: `${(level + 1) * 16}px`, width: `calc(100% - ${(level + 1) * 16}px)` }"
  )

  TransitionGroup(v-if="isOpen", name="slide")
    .expansion-item__tabs(
      v-if="values.next_level && values.next_level === 'equipments'",
      :key="`content-expansion-tabs-${isOpen.toString()}`"
    )
      expansion-row-item-tabs(:level="level", :filter="getFilters()", grid="expenses_dashboard")
    .expansion-item__expansion(
      v-else-if="values.next_level && values.next_level !== 'issues'",
      :key="`content-expansion-${level}-${isOpen.toString()}`"
    )
      .expansion-item__sep(
        :style="{ marginLeft: `${(level + 1) * 16}px`, width: `calc(100% - ${(level + 1) * 16}px)` }"
      )
      expansion-rows(
        :level="level + 1",
        query,
        :path="nextLevelPath",
        :loadColumns="false",
        :nextLevelFilter="getFilters()",
        :nextLevel="values.next_level",
        :levelName="values.next_level"
      )
    .expansion-item__content(v-else, :key="`content-issues-${level}-${isOpen.toString()}`", :class="`level-${level}`")
      expansion-row-item-content(:filter="getFilters()", :level="level")
</template>

<script setup>
import { ref, computed, toRefs } from "vue";

import expansionRows from "./ExpansionRows.vue";
import ExpansionRowItemContent from "./ExpansionRowItemContent.vue";
import ExpansionRowItemTabs from "./ExpansionRowItemTabs.vue";

import DownArrow from "@/assets/icons/grid/down_arrow.svg";
import UpArrow from "@/assets/icons/grid/up_arrow.svg";

const props = defineProps({
  values: {
    type: Object,
    default: () => {},
  },
  columns: {
    type: Array,
    default: () => [],
  },
  level: {
    type: Number,
    default: () => 0,
  },
  query: {
    type: String,
    default: () => "",
  },
  lastItem: {
    type: Boolean,
    default: () => false,
  },
  levelName: {
    type: String,
    required: false,
    default: () => "Objects",
  },
});

const isOpen = ref(false);
const loading = ref(false);
const { values } = toRefs(props);
const { columns } = toRefs(props);
const { level } = toRefs(props);

const openIcon = computed(() => (isOpen.value ? UpArrow : DownArrow));
const nextLevelPath = computed(() => `/api/v3/expenses_dashboard/${values.value.next_level}`);

const isIssuesEmpty = values => {
  return Number(values.issues_count) === 0 && Number(values.ppr_issues_count) === 0;
};

const getColumnPaddingLeft = column => {
  return column.name === "title" && level.value > 0 ? level.value * 16 : 0;
};

const changeOpen = () => {
  isOpen.value = !isOpen.value;
};

const getFilters = () => {
  return {
    type: props.values.type,
    value: props.values.id,
  };
};

const getTitleValue = val => {
  const parseDate = new Date(val);
  if (parseDate.toString() !== "Invalid Date") {
    if (parseDate.toISOString() === val) {
      return `${parseDate.toLocaleDateString("ru")}`;
    }
  }
  return val;
};

const getIconHeightByName = name => {
  switch (name) {
    case "objects":
      return 74;
      break;
    case "group_systems":
      return 82;
      break;
    case "systems":
      return 64;
      break;
    case "group_systems":
      return 52;
      break;
  }
};
</script>

<style lang="scss" scoped>
.expansion-item {
  border-radius: 24px;
  background-color: var(--expenses-content-background-color);
  position: relative;

  &.opened {
    box-shadow: 0px 0px 16px 0px #7f87921f;
  }

  &__head {
    padding: 16px;
    display: flex;
    gap: 16px;

    &__title,
    &__expand {
      display: flex;
      align-items: center;
    }

    :deep(.expand-icon) {
      width: 20px;
      height: 20px;
      cursor: pointer;

      path {
        fill: var(--expenses-main-text-color);
      }
    }

    &__title {
      color: var(--expenses-expansion-text-color);
    }

    .primary {
      width: 100%;
      border-radius: 16px;
      background-color: var(--expenses-expansion-primary-block-background-color);
      display: flex;
      align-items: center;
      gap: 12px;
      padding-right: 15px;

      .header {
        height: 100%;
        width: calc(100% - 64px - 12px);
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &__main {
          font-size: 12x;
          color: #5b7aff;
          font-weight: 600;
        }

        &__secondary {
          font-size: 12px;
          color: var(--expenses-main-text-color);
        }
      }
    }

    &-sep {
      height: 1px;
      background-color: var(--expenses-search-button-border-color);
    }
  }

  &__content {
    max-height: 392px;
    overflow-y: auto;
    padding: 0 16px 16px;
    position: relative;

    &__children--no-data {
      display: flex;
      justify-content: center;
      font-style: italic;
    }

    @for $i from 0 through 3 {
      &.level-#{$i} {
        &::after {
          content: "";
          width: calc(100% - ($i * 16px));
          height: 2px;
          position: absolute;
          top: 0;
          right: 0;
          background-color: var(--expenses-expansion-separator-color);
        }
      }
    }
  }

  &__sep {
    height: 2px;
    background-color: var(--expenses-search-button-border-color);
  }

  // стиль применяется для класса level-#{$i}, где i номер уровня экспансии. Начиная от 2 и до 4
  @for $i from 1 through 3 {
    &.level-#{$i} {
      &::before {
        content: "";
        position: absolute;
        width: 2px;
        top: 0;
        left: $i * 16px;
        right: 0;
        bottom: 0;
        background-image: linear-gradient(#5b7aff 100%, rgba(255, 255, 255, 0) 0%);
        background-position-x: left;
        background-position-y: bottom 25px;
        background-size: 2px 2px;
        background-repeat: repeat-y;
        z-index: 1;
      }

      .expansion-item__head {
        position: relative;
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: calc(50% - 5px);
          width: 12px;
          height: 12px;
          border-radius: 50%;
          border: 2px solid #5b7aff;
          background-color: var(--expansion-list-background);
          z-index: 2;
        }
      }

      &:last-child {
        &::before {
          display: none;
        }

        .expansion-item__head {
          &::after {
            content: "";
            position: absolute;
            width: 2px;
            top: 0;
            left: $i * 16px;
            right: 0;
            bottom: 0;
            background-image: linear-gradient(#5b7aff 100%, rgba(255, 255, 255, 0) 0%);
            background-position-x: left;
            background-position-y: bottom 25px;
            background-size: 2px 2px;
            background-repeat: repeat-y;
            z-index: 1;
            height: calc(50% - 5px);
          }
        }
      }
    }
  }

  &.level-0 {
    .tab-rows {
      margin-top: 0;
    }
  }

  &.level-1 {
    .expansion-item__head {
      &::before {
        left: 12px;
      }
    }
  }
  &.level-2 {
    .expansion-item__head {
      &::before {
        left: 28px;
      }
    }
  }
}

:deep(.expansion-item__head__building-icon) {
  width: auto;
  height: 64px;
  margin: 12px;
}

.rotate-enter-active,
.rotate-leave-active {
  transition: transform 0.3s ease;
}

.rotate-enter-from,
.rotate-leave-to {
  transform: rotate(0.5turn);
}

:deep(.q-toggle__inner--truthy) {
  .q-toggle__thumb:after {
    color: #5b7aff;
  }
  .q-toggle__track {
    color: #adbdff;
  }
}

.slide-leave-active,
.slide-enter-active {
  transition: transform 0.3s ease;
}
.slide-enter {
  transform: translateY(100%);
}
.slide-leave-to {
  transform: translateY(100%);
}
</style>
