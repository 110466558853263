<template lang="pug">
.expansion-dashboard-list__tab
  .tab-header
    q-input.search-field(filled, :value="searchQuery", @input="onSearchInput", debounce="500", :label="searchLabel")
      Transition(name="fade", mode="in-out")
        q-icon.cancel-search(name="cancel", v-if="searchQuery", @click.stop="resetSearch")

    //- horizontal-filters-item(v-bind="filtersData")

  expansion-rows(
    :path="tabPath",
    :query="searchQuery",
    :level="0",
    :loadColumns="true",
    :grid="grid",
    :levelName="levelName"
  )
</template>

<script setup>
import { onMounted, ref, computed } from "vue";
import { currentLocale } from "@/services/useLocales";

import HorizontalFiltersItem from "@/components/shared/general_components/horizontalFiltersList/HorizontalFiltersItem.vue";
import ExpansionRows from "./ExpansionRows.vue";

import { useStore } from "@/store";
import { useEmitter } from "@/services/useEmitter";

const emitter = useEmitter();

const store = useStore();

const props = defineProps({
  tabData: {
    type: Object,
    required: true,
    validator: el => {
      return el.label && el.name && el.path;
    },
  },
  grid: {
    type: String,
    required: true,
  },
  levelName: {
    type: String,
    required: true,
    default: () => "Objects",
  },
});

const tabPath = computed(() => `/api/v3/expenses_dashboard/${props.tabData.path}`);

const searchQuery = ref("");

const searchLabel = currentLocale.value === "ru" ? "Поиск" : "Search";

const onSearchInput = async val => {
  searchQuery.value = val;
  store.commit("updateQuery", { grid_name: props.grid, value: val });
  emitter.emit("refresh-expenses-dashboard-data");
};

const resetSearch = async () => {
  searchQuery.value = "";
  store.commit("resetQuery", { grid_name: props.grid });
  emitter.emit("refresh-expenses-dashboard-data");
};

const loadFilter = async () => {
  // mocked_loadFilter
  return true;
};

onMounted(async () => {
  await loadFilter();
});
</script>

<style lang="scss" scoped>
.expansion-dashboard-list__tab {
  margin-top: 20px;
}

:deep(.tab-header) {
  display: flex;
  gap: 24px;

  .horizontal-filters-list {
    &__item__card {
      padding: 8px 16px;
      border-radius: 8px;
    }
    &__colored-icon {
      width: 20px;
      height: 20px;
    }
    &__counter {
      padding: 2px 8px;
      border: 1px solid var(--link-card-icon-background);
      font-size: 14px;
      min-width: unset;
      min-height: unset;
    }
    &__filter-icon {
      font-size: 25px;
      padding: 0;
    }
  }
}

:deep(.search-field) {
  width: 580px;
  border-radius: 5px;

  .q-field__inner {
    height: 45px !important;

    .q-field__control {
      height: 45px !important;
      border-radius: 8px;
      background-color: var(--expenses-content-background-color);
      border: 1px solid var(--expenses-search-button-border-color);

      .q-field__label {
        color: var(--field-label-color);
        margin-top: -5px;
        font-size: 15px;
      }

      input {
        color: var(--field-input-color);
        margin-top: -5px;
      }
    }

    .cancel-search {
      position: absolute;
      right: 5px;
      margin-top: 16px;
      cursor: pointer;
      color: var(--field-cancel-icon-color);
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
